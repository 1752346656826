import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import SimpleReactValidator from "simple-react-validator"
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

import API from "../api/postings"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import LoadMoreButton from "../components/addon/loadMoreButton"
import Thankyou from '../components/addon/thankYou'
import APIButton from "../components/addon/button/apiButton";

const Testimonial = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;
console.log('components===>',edges)
    let [defaultLimit] = useState(3)
    let [videoData, setVideoData] = useState([])
    let [commentData, setCommentData] = useState([])
    const [selectedVideo, setSelectedVideo] = useState(null)
    let ogForm = {
        webform_id: "testimonials_feedback",
        name: "",
        email: "",
        comments: ""
    }
    const [form, setForm] = useState(ogForm)
    const [thankYou, setThankYou] = useState(false)
    const [captcha, setCaptcha] = useState("");
    const [captchaError, setcaptchaError] = useState(false)
    const [update, forceUpdate] = useState(false)
    const [btnloader, setBtnLoader] = useState(false)
    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) =>
            <p className="field_error" > {message} </p>
    }
    )))

    const [banner, videoSection, commentSection] = data
    const videoSectionText = videoSection?.relationships?.components[0]
    const videoSectionVideos = videoSection?.relationships?.components[0]?.relationships?.field_components
    console.log('commentSection=====',commentSection)
    const commentSectionComments = commentSection ? commentSection?.relationships?.components : null
    // const bannerData = data[0];
    // const bannerTitle = bannerData?.title || "";
    // const bannerSubtitle = bannerData?.subtitle || "";
    // const bannerImage = bannerData?.relationships?.bannerImage?.uri?.url;
    // const bannerImageAlt = bannerData?.bannerImage?.alt.length > 0 ? bannerData?.bannerImage?.alt : "Hinduja Hospital "
    
    const loadMoreVideos = () => {
        const counter = videoData.length + defaultLimit
        const arr = [...videoSectionVideos.slice(videoData.length, counter)]
        setVideoData([...videoData, ...arr])
    }

    const loadMoreComments = () => {
        const counter = commentData.length + defaultLimit
        const arr = [...(commentSectionComments ?? []).slice(commentData.length, counter)]
        setCommentData([...commentData, ...arr])
    }

    const setVideo = (videoUrl) => {
        let data_src = videoUrl.split('=')
        if(data_src[1]) {
            setSelectedVideo(`https://www.youtube.com/embed/${data_src[1]}`)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e?.target
        setForm({
            ...form,
            [name]: value
        })
    }

    const resetForm = () => {
        setForm(ogForm)
        formValidator.current.hideMessages()
        forceUpdate(!update)
    }

    const handleSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
        } else {
            if (validateCaptcha(captcha) === true) {
                setBtnLoader(true)
                API.submitTestimonialForm(form)
                .then(res => {
                    if (res) {
                        setCaptcha("")
                        setThankYou(true);
                        resetForm();
                        let el = document.getElementById('form-section')
                        if(el) {
                            el?.scrollIntoView({behavior: "smooth"})
                        }
                    }
                    setBtnLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setBtnLoader(false)
                })
            } else {
                setcaptchaError(true)
                setCaptcha("");
                forceUpdate(!update)
            }
        }
    }

    const handleCaptcha = (e) => {
        setcaptchaError(false);
        setCaptcha(e.target.value);
    }

    const resetThankyou = (value) => {
        if(window) window.location.reload();
    }

    useEffect(() => {
        loadMoreVideos()
    },[videoSectionVideos])

    useEffect(() => {
        loadMoreComments()
        // setTimeout(() => {
        //     window.readMoreLessFunction();
        // }, 500)
    },[commentSectionComments])
    
    useEffect(() => {
        if(window && window?.readMoreLessFunction)  window?.readMoreLessFunction();
    },[commentData])
    

    useEffect(() => {
        if (thankYou === false) loadCaptchaEnginge(6, "black", "white")
    }, [thankYou])

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/testimonial.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    <section className="section_bg pt-first-section pb-section testimonialWrap">
                        <div className="container">
                            <div className="py-2">
                                <h2>{videoSectionText?.field_title || ''}</h2>
                                <p>{videoSectionText?.field_subtitle || ''}</p>
                            </div>
                            <div className="videoWrap">
                                <div className="row">
                                    {videoData?.length 
                                    ? videoData.map((video, index) => {
                                        let imageUrl = video?.relationships?.image?.uri?.url
                                        let videoUrl = video?.link?.uri
                                        return (
                                            <div className="col-md-4" key={index}>
                                                <div className="videoBox">
                                                    <a href="javascript:;" onClick={() => setVideo(videoUrl)} className="video-popup" data-toggle="modal" data-target="#modal_video" data-src="q_mU0XUmlfY">
                                                        <img src={imageUrl} alt={video?.image?.alt} />
                                                        <span className="playIcon"><img src="/assets/images/testimonial/video-play-icon.png" alt="Video Play Icon" /></span>
                                                    </a>
                                                    <h4>{video?.title}</h4>
                                                </div>  
                                            </div>
                                        )
                                    })
                                    : null}
                                    {videoData.length != videoSectionVideos.length 
                                    ? <div className="col-md-12">
                                        <div className="btnWrap text-center">
                                            <LoadMoreButton
                                                label="Load More"
                                                callbackFunction={loadMoreVideos}
                                            />
                                        </div>  
                                    </div>
                                    : null}
                                </div>    
                            </div>
                            {commentSection && <div className="expereanceWrap">
                                <h2>{commentSection?.name}</h2>
                                <div className="expereanceContent">
                                    {commentData?.length 
                                    ?  commentData.map((comment, index) => {
                                        return (
                                            <div className="expereanceBox" key={index}>
                                                <span className="quotes openQuotes">
                                                    <img src="/assets/images/testimonial/open-quotes.svg" alt="Open Quotes Icon" />
                                                </span>  
                                                <div className="textContent">
                                                    <div className="more" dangerouslySetInnerHTML={{__html: comment?.text?.processed }}></div>  
                                                    <h4>- {comment?.field_title}</h4>
                                                </div>
                                                <span className="quotes closeQuotes">
                                                    <img src="/assets/images/testimonial/close-quotes.svg" alt="Close Quotes Icon" />
                                                </span>  
                                            </div>  
                                        )
                                    })
                                    : null}
                                    {(commentData && commentSectionComments) && commentData.length != commentSectionComments.length 
                                    ? <div className="col-md-12">
                                        <div className="btnWrap text-center">
                                            <LoadMoreButton
                                                label="Load More"
                                                callbackFunction={loadMoreComments}
                                            />
                                        </div>  
                                    </div>
                                    : null}
                                </div>
                            </div>}
                            <div id="form-section">
                                <h3>Share your experience</h3>
                                {thankYou 
                                    ? <Thankyou setThankYou={resetThankyou} /> 
                                    : <div className="writeExpereance asdasda">
                                    <div className="form_wrap">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input className="form-control" name="name" onChange={handleInputChange} type="text" value={form?.name} placeholder="Name *" /> 
                                                    {formValidator.current.message('name', form.name, 'required|alpha_space')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input className="form-control" name="email" onChange={handleInputChange} type="text" value={form?.email} placeholder="Email *" /> 
                                                    {formValidator.current.message('email', form.email, 'required|email')}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <textarea id="comments" name="comments" onChange={handleInputChange} rows="4" cols="50" value={form?.comments} placeholder="Write your experience*"></textarea> 
                                                    {formValidator.current.message('comments', form.comments, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <LoadCanvasTemplate />
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control" type="text" value={captcha} placeholder="Enter Captcha *"  onChange={handleCaptcha} id="captcha" />
                                                    {formValidator.current.message('captcha', captcha, 'required')}
                                                    {captchaError ? <p className="field_error">Invalid captcha value</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <APIButton 
                                                    title={`Submit`}
                                                    loading={btnloader}
                                                    disabled={btnloader}
                                                    onBtnClick={() => handleSubmit()}  
                                                    className={`btn btn-primary`} 
                                                />
                                                {/* <button className="btn btn-primary" onClick={handleSubmit}>SUBMIT</button> */}
                                            </div>
                                        </div>  
                                    </div>    
                                </div> }
                            </div>
                        </div>
                    </section>
                </main>
                <div className="modal fade modal_video modal-center" id="modal_video" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                        
                            <div className="modal-body">
                                <button onClick={() => setSelectedVideo(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">X</span>
                                </button>
                                <iframe width="100%" height="315" src={selectedVideo ? selectedVideo : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export const query = graphql`
query testimonial {
    allNodeTemplatePages(filter: {path: {alias: {eq: "/testimonial"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
          }
          metatag_normalized {
            attributes {
              content
              name
              property
            }
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphSectionT
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphSectionT on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphBannerT
        ...ParagraphTitleAndDescriptionT
        ... on paragraph__title_and_description_components {
          id
          field_name
          field_title
          field_subtitle
          field_link {
            uri
            title
          }
          relationships {
            field_components {
              ...testParagraphImageTitleDescLink
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphBannerT on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    bannerImage: field_banner_image {
      alt
    }
    bannerLink: field_banner_link {
      uri
      title
    }
    relationships {
      bannerImage: field_banner_image {
        id
        uri {
            value
            url
        }
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptionT on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
    field_text {
      processed
    }
  }
  
  fragment testParagraphImageTitleDescLink on paragraph__image_title_desc_link {
    id
    title: field_title
    title1: field_title_1
    description: field_description {
      processed
    }
    link: field_link {
      uri
      title
    }
    image: field_image {
      alt
    }
    relationships {
      image: field_image {
        id
        uri {
            value
            url
        }
      }
    }
  }
`

export default Testimonial